import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/components/Home";
import DownloadResults from "@/components/DownloadResults";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/:taskUID',
        name: 'TaskResults',
        props: { taskUID: true },
        component: DownloadResults
    },
]

const router = new VueRouter({routes})

export default router