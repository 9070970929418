import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/firebase-storage'

export const config = {
    apiKey: "AIzaSyAfpebU3Y9Nn8RlCKjSPtQ0Op6qY1_vp9M",
    authDomain: "taskdash-8ce3e.firebaseapp.com",
    databaseURL: "https://taskdash-8ce3e.firebaseio.com",
    projectId: "taskdash-8ce3e",
    storageBucket: "taskdash-8ce3e.appspot.com",
    messagingSenderId: "560907531230",
    appId: "1:560907531230:web:9881231a3b240b31ff234f"
}

const firebaseApp = firebase.initializeApp(config)

export const tasks = firebaseApp.firestore().collection('tasks')
export const orders = firebaseApp.firestore().collection('orders')