<template>
  <div>
    <v-card
      v-if="loaded"
      class="mx-auto card"
      :max-width="mobile ? '90vw' : '600px'"
    >
      <v-img
        class="white--text align-end blurred"
        height="300px"
        position="center center"
        :src="files.taskImages[0]"
      >
      </v-img>

      <v-card-title style="word-break: keep-all" class="title">
       {{statusMessage}}
      </v-card-title>

      <v-card-text class="text--primary">
        <v-container fluid>
          <v-row
            v-if="showResults"
            style="width: 100%"
            align="center"
            justify="center"
            no-gutters
          >
            <v-col
              v-for="file in this.files.results"
              :key="file"
              cols="12"
              sm="12"
              md="6"
              xl="6"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card @click="downloadImage(file)" class="margin">
                  <v-img aspect-ratio="1" :src="file">
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex download-container transition-fast-in-fast-out grey darken-2 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-icon class="download-icon" dark size="50">mdi-download</v-icon>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <div class="img-container">
          <v-img contain position="center" width="80%" alt="Aktueller Status" :src="progressImg" class="progress-img"></v-img>
        </div>
        <v-divider></v-divider>
        <div v-if="shipment && !shipment.inCreation">
          <v-card-title>Versendet!</v-card-title>
          <v-card-text>
            <p>
              <span>Versand durch: {{shipment.carrier}}</span>
            </p>
            <p>
              <span>Tracking Nummer: </span>
              <a :href="shipment.tracking_url">{{shipment.carrier_tracking_no}}</a>
            </p>
          </v-card-text>
        </div>
        <div v-else>
          <v-card-title>Versand</v-card-title>
          <v-card-text>Noch nicht versendet, wir beeilen uns ...🏃</v-card-text>
        </div>
      </v-card-text>
    </v-card>
    <v-progress-circular class="loading" size="100" width="10" color="blue" v-else-if="!error" indeterminate></v-progress-circular>
    <v-card class="mx-auto not-found" v-if="error">
      <v-card-text class="text-lg-center">
        Wir finden dein Portrait nicht, bitte melde dich beim Support unter Lisa@TierBeiMir.com
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {orders, tasks} from '../../fb'

export default {
  name: 'DownloadResults',
  data () {
    return {
      files: [],
      customerName: '',
      orderID: '',
      loaded: false,
      error: false,
      numberOfDrawings: '',
      shipment: {},
      productStatus: '',
      mobile: window.innerWidth < 500
    }
  },
  created () {
    this.load()
  },
  computed: {
    progressImg () {
      const fileName = this.productType + ('_' + this.getProductStatus() || '_drawed')
      console.log(fileName)
      try {
        return require(`../assets/nextSteps/${fileName}.png`)
      } catch (e) {
        const fallBack ='default_' + this.getProductStatus() || '_drawed'
        return require(`../assets/nextSteps/${fallBack}.png`)
      }
    },
    statusMessage () {
      switch (this.getProductStatus()) {
        case 'drawed':
          return this.numberOfDrawings > 1 ? `Hey ${this.customerName}, wir haben Deine Lieblinge gezeichnet` : `Hey ${this.customerName}, wir haben Dein Liebling gezeichnet`
        case 'produced':
          return this.numberOfDrawings > 1 ? `Hey ${this.customerName}, wir haben Deine Lieblinge gezeichnet und versenden ${this.getProductType()} bald` : `Hey ${this.customerName}, wir haben Deine Lieblinge gezeichnet und versenden ${this.getProductType()} bald`
        case 'shipped':
          return this.numberOfDrawings > 1 ? `Hey ${this.customerName}, wir haben Deine Lieblinge gezeichnet und ${this.getProductType()} versendet.` : `Hey ${this.customerName}, wir haben Deine Lieblinge gezeichnet ${this.getProductType()} versendet.`
        case 'inDrawing':
          return this.numberOfDrawings > 1 ? `Hey ${this.customerName}, wir zeichnen grade Deine Lieblinge` : `Hey ${this.customerName}, wir zeichnen grade Dein Liebling`
        case 'received':
          return this.numberOfDrawings > 1 ? `Hey ${this.customerName}, wir werden bald mit dem zeichnen Deiner Lieblinge beginnen` : `Hey ${this.customerName}, wir werden bald mit dem zeichnen Deines Lieblings beginnen`
        default:
          return this.numberOfDrawings > 1 ? `Hey ${this.customerName}, wir werden bald mit dem zeichnen Deiner Lieblinge beginnen` : `Hey ${this.customerName}, wir werden bald mit dem zeichnen Deines Lieblings beginnen`
      }
    },
    showResults () {
      return this.getProductStatus() !== 'inDrawing' && this.getProductStatus() !== 'received'
    }
  },
  methods: {
    load () {
      console.log(this.$route)
      const taskUID = this.$route.params.taskUID
      tasks.doc(taskUID).get().then(result => {
        if (result.exists) {
          this.files = result.data().files
          this.customerName = result.data().customer?.name || ''
          this.orderID = result.data().orderID
          this.numberOfDrawings = result.data().numberOfDrawings
          this.loaded = true
          this.status = result.data().status
          this.productType = result.data().productType

          orders.doc(result.data().orderUID).get().then(result => {
            this.shipment = result.data().shipment

            console.log(result.data().shipment)
          })
        }
      }).catch((error) => {
        this.error = true
        console.log(error)
      })
    },
    getProductType () {
      switch (this.productType) {
        case 'print':
          return 'den Druck'
        case 'sticker':
          return 'die Sticker'
        case 'blanket':
          return 'die Decke'
        default:
          return 'das Produkt'
      }
    },
    getProductStatus () {
      if (this.shipment && !this.shipment.inCreation) {
        return 'shipped'
      }
      switch (this.status) {
        case 'READY_FOR_FINALIZATION':
          return 'drawed'
        case 'READY_FOR_SHIPMENT':
          return 'produced'
        case 'CLOSED':
          return 'shipped'
        case 'IN_PROGRESS':
        case 'IN_REVISION':
        case 'FOR_REVISION':
        case 'AWAITING_APPROVAL':
          return 'inDrawing'
        case 'ON_MARKET':
        case 'ASSIGNED_TO_EDITOR':
        case 'ASSIGNED_TO_MANAGER':
          return 'received'
        default:
          return 'received'
      }
    },
    downloadImage (fileURL) {
      const url = fileURL
      const filenameFull = url.substring(url.lastIndexOf('/') + 1, url.toString().length).split('%2F')[2]
      const filename = filenameFull.substr(0, filenameFull.lastIndexOf('.'))
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.onload = function () {
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(xhr.response)
        a.download = filename + '.png'
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
      }
      xhr.open('GET', url)
      xhr.send()
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    min-height: 500px;
    margin: 20px;
  }
  .blurred {
    filter: blur(2px);
  }
  .margin {
    margin: auto;
  }
  .loading {
    position: absolute;
    top: calc(50% - 50px);
    right: calc(50% - 50px);
  }
  .title {
    position: absolute;
    color: white;
    top: 150px;
    height: fit-content;
    width: 100%;
    background: rgba(55, 55, 55, 0.46);
    text-align-all: center;
  }

  .not-found {
    padding-top: 100px;
    padding-bottom: 100px;
    font-size: 66px;
  }

  .progress-img {
    margin: 50px 0 50px 0;
    max-width: 500px;
  }

  .img-container {
    display: flex;
    justify-content: center;
  }

  .download-container {
    opacity: 0.5;
  }

  .download-icon {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
  }
</style>
