<template>
  <v-app>
    <v-main>
      <div class="main-container"></div>
      <v-img :width="mobile ? '80%': '400px'" class="mx-auto logo" :src="siteLogo"></v-img>
      <router-view class="main-content"></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      mobile: window.innerWidth < 500
    }
  },
  components: {
  },
  computed: {
    siteLogo () {
      return 'https://firebasestorage.googleapis.com/v0/b/taskdash-8ce3e.appspot.com/o/websiteFiles%2Flogo.png?alt=media&token=589ecb51-6645-4e4f-a377-cbd24cfcdb45'
    }
  },
};
</script>

<style lang="scss">
.logo {
  margin: 50px;
  //filter: invert(1);
}

//.main-container {
//  //position: absolute;
//  //width: 100%;
//  //height: 100%;
//  ////background-image: url('https://cdn.shopify.com/s/files/1/0258/1918/5238/files/tiere-viele.jpg?v=1572888678%202500w%202500h');
//  ////filter: blur(8px);
//  ////-webkit-filter: blur(15px);
//  ////height: 100%;
//  ////background-position: center;
//  ////background-repeat: no-repeat;
//  ////background-size: cover;
//  //background: #c6e6f1;
//}

.main-container {
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 100s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes para {
  100% {
    background-position:
        -5000px 20%,
        -800px 95%,
        500px 50%,
        1000px 100%,
        400px 0;
  }
}
</style>
